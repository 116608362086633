<script>
  import { onMount } from "svelte";

  let isMounted = false;

  let eduProgs = [];
  let abitList = {};

  let eduProg;
  let abits = [];

  let specialities = [];
  let eduForms = [];
  let baseEduLevels = [];
  let finSources = [];

  let selSpeciality = "";
  let selEduForm = "";
  let selBaseEduLevel = "";
  let selFinSource = "";

  $: selEduProgs = eduProgs.filter((ep) => {
    return (
      ep.speciality === selSpeciality &&
      ep.eduForm === selEduForm &&
      ep.baseEduLevel === selBaseEduLevel &&
      ep.finSource === selFinSource
    );
  });

  $: {
    eduProg = selEduProgs.length === 1 ? selEduProgs[0] : null;
  }
  $: {
    abits = eduProg === null ? [] : abitList[eduProg.code];
  }

  $: if (selSpeciality) localStorage.selSpeciality = selSpeciality;
  $: if (selEduForm) localStorage.selEduForm = selEduForm;
  $: if (selBaseEduLevel) localStorage.selBaseEduLevel = selBaseEduLevel;
  $: if (selFinSource) localStorage.selFinSource = selFinSource;

  onMount(async () => {
    const res = await fetch(`https://dvggtk.su/api/abits-list-2022`);
    // const res = await fetch(`http://localhost:3000/api/abits-list-2022`);
    const data = await res.json();

    eduProgs = data.eduProgs.map(
      ([
        eduProg,
        ,
        speciality,
        eduForm,
        baseEduLevel,
        finSource,
        placesNumber,
      ]) => ({
        code: eduProg,
        speciality,
        eduForm,
        baseEduLevel,
        finSource,
        placesNumber,
      })
    );

    for (const { code: eduProg } of eduProgs) {
      abitList[eduProg] = [];
    }

    for (const [
      eduProg,
      fio,
      hasEduCertOriginal,
      certScore,
      extraScore,
      totalScore,
      grade,
      rank,
    ] of data.abitList) {
      if (!Array.isArray(abitList[eduProg])) continue;

      abitList[eduProg].push({
        fio,
        hasEduCertOriginal,
        certScore,
        extraScore,
        totalScore,
        grade,
        rank,
      });
    }

    // ensuring abits are in right order
    for (const { code: eduProg } of eduProgs) {
      abitList[eduProg].sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
        return 0;
      });
    }

    specialities = Array.from(
      new Set(eduProgs.map((ep) => ep.speciality))
    ).sort();
    eduForms = Array.from(new Set(eduProgs.map((ep) => ep.eduForm)))
      .sort()
      .reverse();
    finSources = Array.from(new Set(eduProgs.map((ep) => ep.finSource))).sort();
    baseEduLevels = Array.from(
      new Set(eduProgs.map((ep) => ep.baseEduLevel))
    ).sort();

    selSpeciality = localStorage.selSpeciality || specialities[0];
    selEduForm = localStorage.selEduForm || eduForms[0];
    selBaseEduLevel = localStorage.selBaseEduLevel || baseEduLevels[0];
    selFinSource = localStorage.selFinSource || finSources[0];

    isMounted = true;
  });
</script>

<div class="container">
  <h1>Абитуриенты ДВГГТК</h1>

  {#if isMounted}
    <label class="control">
      <span class="label">Специальность</span>
      <select bind:value={selSpeciality}>
        {#each specialities as speciality}
          <option value={speciality}>{speciality}</option>
        {/each}
      </select>
    </label>

    <label class="control">
      <span class="label">Форма обучения</span>
      <select bind:value={selEduForm}>
        {#each eduForms as eduForm}
          <option>{eduForm}</option>
        {/each}
      </select>
    </label>

    <label class="control">
      <span class="label">Базовое образование</span>
      <select bind:value={selBaseEduLevel}>
        {#each baseEduLevels as baseEduLevel}
          <option>{baseEduLevel}</option>
        {/each}
      </select>
    </label>

    <label class="control">
      <span class="label">Источник финансирования</span>
      <select bind:value={selFinSource}>
        {#each finSources as finSource}
          <option>{finSource}</option>
        {/each}
      </select>
    </label>

    {#if selEduProgs.length > 0}
      <ul class="property-list">
        <div class="property-list__item">
          <span>Количество абитуриентов:</span><span>{abits.length}</span>
        </div>
        <div class="property-list__item">
          <span>Количество мест:</span><span>{eduProg.placesNumber}</span>
        </div>
      </ul>

      <p class="abbrs">
        ПДО -- подлинник документа об образовании<br />
        КБ -- конкурсный балл<br />
        ПР -- оценка по профильному предмету<br />
      </p>

      <ul class="abit-list">
        <li class="abit-header">
          <div class="abit-header__number" />
          <div class="abit-header__fio" />
          <div class="abit-header__cert" />
          <div class="abit-header__total-score" />
          <div class="abit-header__grade" />
        </li>
        {#each abits as abit, idx}
          <li class="abit" data-rank={abit.rank}>
            <div class="abit__number">{idx + 1}</div>
            <div class="abit__fio">{abit.fio}</div>
            <div class="abit__cert" data-cert={abit.hasEduCertOriginal} />
            <div class="abit__total-score">
              {(abit.totalScore || "").toString().replace(".", ",")}
            </div>
            <div class="abit__grade">{abit.grade || ""}</div>
          </li>
        {/each}
      </ul>
    {:else}
      <div>Набор не производится</div>
    {/if}
  {/if}
</div>

<style>
  .control {
    display: flex;
    flex-direction: column;
  }

  .label {
    display: block;
    min-width: 24ch;
  }

  .abit-list {
    list-style: none;
    padding: 0;
    display: table;
    width: 100%;
  }

  .abit-header {
    display: table-row;
    background-color: #ccc;
  }

  .abit-header > * {
    display: table-cell;
    text-align: center;
  }

  .abit-header__number::before {
    content: "№";
  }
  .abit-header__fio::before {
    content: "ФИО";
  }
  .abit-header__cert::before {
    content: "ПДО";
  }
  .abit-header__total-score::before {
    content: "КБ";
  }
  .abit-header__grade::before {
    content: "ПР";
  }

  @media (min-width: 600px) {
    .abbrs {
      display: none;
    }

    .abit-header__cert::before {
      content: "Подлинник документа об образовании";
    }
    .abit-header__total-score::before {
      content: "Конкурсный балл";
    }
    .abit-header__grade::before {
      content: "Оценка по профильному предмету";
    }
  }

  .abit-header__fio {
    min-width: 20ch;
  }
  .abit-header__cert {
    max-width: 13ch;
  }
  .abit-header__total-score {
    max-width: 13ch;
  }
  .abit-header__grade {
    max-width: 13ch;
  }

  .abit {
    display: table-row;
  }

  .abit + .abit > * {
    border-top: 1px solid #ccc;
  }

  .abit__number {
    display: table-cell;
    padding: 5px;
  }

  .abit__fio {
    display: table-cell;
    padding: 5px;
  }

  .abit__cert {
    display: table-cell;
    padding: 5px;
    text-align: center;
  }

  .abit__cert::before {
    content: "";
    display: inline-block;
    white-space: nowrap;
  }
  .abit__cert[data-cert="true"]::before {
    content: "✔️";
  }

  .abit__total-score {
    display: table-cell;
    text-align: center;
    padding: 5px;
  }

  .abit__grade {
    text-align: center;
    display: table-cell;
    padding: 5px;
  }

  .property-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 270px;
  }

  .property-list__item {
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .container {
    max-width: 768px;
    width: 100%;
  }
</style>
